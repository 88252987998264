import React from "react"
import PropTypes from "prop-types"
import Cookies from 'js-cookie';
import SEO from "../components/seo"
import Header from "../components/header"
import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import Footer from "../components/Footer"
import BirthConsent from "../components/BirthConsent"
import fbc from '../images/bcFirstDivider.png'
import sbc from '../images/bcSecondDivider.png'
import lupuloMenu from '../images/lupulo-verticalmenu.png'
import holaArrow from '../images/holaArrowandline.png'
import holaArrow2 from '../images/hola/holaArrow2.png'
import arrow2006 from '../images/2006arrow.png'
import ilus2006 from '../images/2006ilus.png'
import image2006 from '../images/hola2006image.png'
import end2006 from '../images/end2006.png'
import image2010 from '../images/image2010.png'
import ilus2010 from '../images/ilus2010.png'
import ilus20102 from '../images/hola/ilus20102.png'
import end2010 from '../images/end2010.png'
import ilus2015 from '../images/hola/2015/ilus2015.png'
import ilus20152 from '../images/hola/2015/ilus20152.png'
import end2015 from '../images/hola/2015/end2015.png'
import image2015 from '../images/hola/2015/image2015.png'
import image2016 from '../images/hola/2016/image2016.png'
import ilus2016 from '../images/hola/2016/ilus2016.png'
import ilus20162 from '../images/hola/2016/ilus20162.png'
import ilus2017 from '../images/hola/2017/ilus2017.png'
import ilus20172 from '../images/hola/2017/ilus20172.png'
import image2017 from '../images/hola/2017/image2017.png'
import ilusManifest from '../images/hola/manifest/ilusManifest2.png'
import ilusManifestMobile from '../images/hola/manifest/manifestTitleMobile.png'
import arrowManifest from '../images/hola/manifest/manifestArrow.png'
import principiosArrow from  '../images/hola/principios/principiosArrow.png'
import principiosDecor from  '../images/hola/principios/principiosDecorationText.png'
import principiosIlus1 from  '../images/hola/principios/principiosIlus1.png'
import principiosIlus2 from  '../images/hola/principios/principiosIlus2.png'
import principiosIlus3 from  '../images/hola/principios/principiosIlus3.png'
import principiosIlus4 from  '../images/hola/principios/principiosIlus4.png'
import principiosIlus5 from  '../images/hola/principios/principiosIlus5.png'

import holaMisc from '../images/hola/holaMiscSpan.png'
import spanMiscDecor from '../images/hola/spanMiscDecor.png'
import squareLagos from '../images/hola/manifest/squareLagos.png'

import holaContainer from '../images/hola/holaContainer2.svg';
import holaContainerMobile from '../images/hola/holaContainerMobile.svg';
import holaManifestContainer from '../images/hola/holaManifestContainer.svg';
import holaManifestContainer2 from '../images/hola/holaManifestContainer2.svg';
import { ReactSVG } from 'react-svg'

const hasConfirmed = () => {
  return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
    || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
};



export default class hola1 extends React.Component {
  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      isScrolling: false,
      menuOpen: false,
      menuClass: 'slideOut',
      isDark: false,
      width: 1000,
      birthConsent: true,
      sectionName: 'Nuestra Historia',
      isHola: true
    }
  }

  componentDidMount() {

    window.addEventListener("scroll", this.onScroll);
    this.setState({
      width: window.innerWidth,
    })

    if (hasConfirmed()){

      this.handleConsent()
    }
    else {
      this.setState({
        birthConsent: false,
      })
    }
  }

  handleConsent = () =>{
    this.setState({
      birthConsent: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onMenuToggle = () =>{
    this.setState({
      menuOpen: !this.state.menuOpen,
      menuClass: this.state.menuOpen ? 'slideOut' : 'slideIn',
      isDark: !this.state.menuOpen,
      isHola: !this.state.menuOpen,
    })
  };

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this.state.isScrolling){
        this.setState({ isScrolling: false });
      }
    }, 200);

    var section = document.getElementsByTagName("SECTION");



    var bScroll = document.scrollingElement.scrollTop;
    for (var i = 0; i < section.length; i++) {

      var sHeight = section[i].offsetHeight;
      var offsets = section[i].offsetTop;

      if (bScroll > offsets && bScroll < offsets + sHeight) {
        // console.log(section[i].className)
        if (section[i].className !== 'holaHome'){
          this.setState({ isDark: true, isHola: true });
        }
        else if (section[i].className === 'holaHome') {
          this.setState({ isDark: false, isHola: true });
        }
        if (section[i].className === 'holaManifest'){
          this.setState({sectionName: 'Nuestro Manifiesto'})
        }else if (section[i].className === 'holaPrincipios'){
          this.setState({sectionName: 'Nuestros Principios'})
        }
        else {
          this.setState({sectionName: 'Nuestra Historia'})
        }

      }


    }

  };

  render() {
    console.log(this.state);
    
    return (
      <div id={'hola'}>
        <SEO title="Nosotros" />

        {this.state.birthConsent &&
        <div>
          <Header 
          isScrolling={this.state.isScrolling} onMenuToggle={this.onMenuToggle} isDark={this.state.isDark} 
          isHola={this.state.isHola} 
          menuOpen={this.state.menuOpen} 
          width={this.state.width}
                  >
          <div goTo="#nuestra-historia" text={'Nuestra historia'}/>
          <div goTo="#nuestro-manifiesto" text={'Nuestro Manifiesto'}/>       
          </Header> 
          <div className={'breadcrumbsContainer'}>
            <div className={'breadcrumbsWrapper'}>
              <span className={'bcSection'}>{this.state.sectionName}</span>
              <img  alt="" className={'fbc'} src={fbc}/>
              <span className={'bcPage'}>Nosotros</span>
              <img  alt="" className={'sbc'} src={sbc}/>
              <img  alt="" className={'bcLupulo'} src={lupuloMenu}/>
            </div>
          </div>


          <section className={'holaHome'}>
            <div className="navHome">
              {/* <div className="navHomeTextContainer">
                <a href="#nuestra-historia" className="aHomeText1"><h1 className="navHomeText1">Nuestra Historia</h1></a>
                <a href="#nuestro-manifiesto" className="aHomeText2"><h1 className="navHomeText2">Nuestro Manifiesto</h1></a>
              </div> */}
            </div>
            <div id='nuestra-historia'>
            <ReactSVG className={'holaSvg'}
                      beforeInjection={svg => {
                        svg.setAttribute('style', 'width: 100%; height: auto; margin-top: 5%');
                      }}
                      src={holaContainer}/>
            </div>

            <div id="nuestro-manifiesto">
            <ReactSVG className={'holaSvg2'}
                      beforeInjection={svg => {
                        svg.setAttribute('style', 'width: 100%; height: auto')
                      }}
                      src={holaContainerMobile}/>
            </div>
          </section>
          {/*<div className={'holaContentContainer'}>
              <div className={'holaText'}>
                <div className={'holaTitle'}>
                  Nuestra Historia
                </div>
                <img  alt="" className={'holaArrow2'} src={holaArrow2}/>
                <div className={'holaDescription'}>
                  <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}>Todo comenzó en el garage de una casa </span>
                   al sur de la ciudad de Buenos Aires cuando un Maestro Cervecero de AB-InBev elaboraba
                  <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> una cerveza con lúpulo patagónico para
                    compartir con amigos.</span> Aún sin darse cuenta, esta cerveza de suave sabor a caramelo, había
                  marcado un nuevo rumbo.
                </div>
              </div>
              <img  alt="" src={holaArrow} alt={'holaArrow'} className={'holaArrow'}/>
            </div>
          </section>
          <section className={'hola2006'}>
              <div className={'content2006'}>
                <div className={'titleDesc2006'}>
                  <div className={'titleilus2006'}>
                    <div className={'title2006'}>
                      <div className={'titleLine2006'}></div>
                      <div className={'titleText2006'}>2006</div>
                      <img  alt="" src={arrow2006} className={'arrow2006'}/>
                    </div>
                    <img  alt="" className={'ilus2006'} src={ilus2006}/>
                  </div>
                  <div className={'description2006'}>
                    La receta del Maestro se convierte en la
                    <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> cerveza “Patagonia<span style={{backgroundImage: 'url('+holaMisc+')', backgroundSize: '100% 100%' }}> Amber </span>Lager”</span> y sale a la venta transformándose en poco tiempo en la opción de los que <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> buscaban un sabor distintivo.</span> Patagonia había llegado para <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> prestigiar la categoría de cervezas.</span>
                  </div>
                  <img  alt="" src={end2006} className={'end2006'}/>
                </div>
                <img  alt="" src={image2006} className={'image2006'}/>

              </div>
          </section>
          <section className={'hola2010'}>
            <img  alt="" src={image2010} className={'image2010'}/>
            <div className={'ilustext2010'}>
              <img  alt="" src={ilus2010} className={'ilus2010'}/>
              <img  alt="" src={ilus20102} className={'ilus20102'}/>
              <div className={'description2010'}>
                Presentamos dos variedades nuevas pensadas para un <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> paladar más soﬁsticado</span> y elegimos enfocarlas en maridaje:
                <br/><span style={{fontFamily: 'AmsiPro-black, sans-serif', backgroundImage: 'url('+ holaMisc +')', backgroundSize: '100% 100%'}}> Bohemian Pilsener y Weisse. </span>
                La primera se trata de una cerveza brillante con lúpulos checos y la segunda es nuestra cerveza estilo witbier.
              </div>
              <img  alt="" className={'end2010'} src={end2010}/>
            </div>
          </section>
          <section className={'hola2015'}>
            <div className={'ilustext2015'}>
              <img  alt="" className={'ilus2015'} src={ilus2015}/>
              <img  alt=""  alt="" className={'ilus20152'} src={ilus20152}/>
              <div className={'description2015'}>
                <span style={{fontFamily: 'AmsiPro-black, sans-serif', backgroundImage: 'url('+holaMisc+')', backgroundSize: '100% 100%'}}>Nace Küné, </span> nuestra Pale Ale con la que <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> nos impulsamos al mundo craft. </span> Su nombre es de origen mapuche y signiﬁca “mellizos”. <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> La llamamos así en honor a Victoria, el lúpulo que le da aroma, y a Mapuche, el lúpulo que le da su amargor. </span>
              </div>
              <img  alt="" src={end2015} className={'end2015'}/>
            </div>
            <img  alt="" src={image2015} className={'image2015'}/>
          </section>
          <section className={'hola2016'}>
            <img  alt="" src={image2016} className={'image2016'}/>
            <div className={'ilustext2016'}>
              <img  alt="" src={ilus2016} className={'ilus2016'}/>
              <img  alt="" src={ilus20162} className={'ilus20162'}/>
              <div className={'description2016'}>
                <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> Inauguramos nuestra<span style={{ backgroundImage: 'url('+ spanMiscDecor +')', backgroundSize: '100% 100%'}}> Microcervecería </span></span> en el kilómetro 24.7 de la Ruta 77, Circuito Chico, Bariloche. <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> Para llevar desde allí nuestra cerveza al mundo. </span>
              </div>
            </div>
          </section>
          <section className={'hola2017'}>
            <div className={'ilustext2017'}>
              <img  alt="" className={'ilus2017'} src={ilus2017}/>
              <img  alt="" className={'ilus20172'} src={ilus20172}/>
              <div className={'description2017'}>
                Lanzamos <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> nuestra session IPA con sauco, la<span style={{backgroundImage: 'url('+holaMisc+')', backgroundSize: '100% 100%'}}> KM 24.7. </span></span>
                La cerveza que hicimos
                con lúpulos patagónicos
                <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> en homenaje a nuestra Micro. </span> Desde entonces, se sucedieron <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> aperturas de nuevos Refugios, colaboraciones con otras cervecerías, y variedades seasonals, </span> continuando así <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> nuestra misión de llevar
                la mejor experiencia cervecera
                a toda la región. </span>
              </div>

            </div>
            <div className={'imageend2017'}>
              <img  alt="" src={image2017} className={'image2017'}/>
              <img  alt="" src={end2015} className={'end2017'}/>
            </div>
          </section>*/}
          <section className={'holaManifest'}>
            <ReactSVG className={'holaSvg'}
                      beforeInjection={svg => {
                        svg.setAttribute('style', 'width: 100%; height: auto')
                      }}
                      src={holaManifestContainer}/>
            <ReactSVG className={'holaSvg2'}
                      beforeInjection={svg => {
                        svg.setAttribute('style', 'width: 100%; height: auto')
                      }}
                      src={holaManifestContainer2}
                      />

            {/*<div className={'manifestContent'}>
              <img  alt="" className={'ilusManifest'} src={this.state.width > 767 ? ilusManifest : ilusManifestMobile}/>
              <div className={'descriptionManifest'}>
                En la patagonia necesitamos menos cosas.
                Acá<span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> valoramos<span style={{backgroundImage: 'url('+holaMisc+')', backgroundSize: '100% 100%'}}> la naturaleza, </span></span>
                sus sonidos, sus silencios, los paisajes.
                Preferimos calentarnos las manos al fuego
                y sentarnos frente a una comida casera.
                Acá tomamos agua de<span style={{backgroundImage: 'url('+squareLagos+')', backgroundSize: '100% 100%'}}> los lagos.</span>
                <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> Nos inspiramos cuando miramos una montaña </span>
                y gracias a esa inspiración
                <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> empezamos búsquedas que nos llevan
                  a descubrir aromas, texturas y sabores nuevos. </span>
                Hacer todo con nuestras manos es una sensación que algún día todos deberíamos sentir.
                <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> En el sur volvemos al principio,
                  a<span style={{backgroundImage: 'url('+squareLagos+')', backgroundSize: '100% 100%'}}> valorar </span>lo que tenemos
                  y a darle gracias a la tierra que nos inspira. </span> Porque nos invita a explorar,
                a buscar nuevas experiencias
                y a proponernos <span style={{fontFamily: 'AmsiPro-black, sans-serif'}}> cosas tan desafiantes
                como hacer la mejor cerveza del mundo </span>
              </div>
              <img  alt="" className={'arrowManifest'} src={arrowManifest}/>
            </div>*/}
          </section>
          <section className={'holaPrincipios'}>
            <div className={'principiosTitle'}>
              Nuestros Principios
            </div>
            <img  alt="" src={principiosArrow} className={'principiosArrow'}/>
            <div className={'principiosItemsContainer'}>
              <div className={'principiosItem'}>
                <img  alt="" className={'principiosItemImage'} src={principiosIlus1}/>
                <div className={'principiosItemTitleDecor'}>
                  <div>
                    <div className={'principiosItemTitle'}>01</div>
                    <img  alt="" className={'principiosDecor'} src={principiosDecor}/>
                  </div>
                  <div className={'principiosItemTitle principiosTitleAfter'}>EL ORIGEN COMO <br/>INSPIRACIÓN.</div>

                </div>
                <div className={'principiosDescription'}>
                  En la Patagonia existe otra noción del tiempo. Es un lugar donde la vorágine se detiene y uno puede enfocarse en volver a conectar con el presente. Esta sensación, que hay que vivir para comprender, es la experiencia que queremos transmitir cada vez que alguien abra una de nuestras cervezas.
                </div>

              </div>
              <div className={'principiosItem'}>
                <img  alt="" className={'principiosItemImage'} src={principiosIlus2}/>
                <div className={'principiosItemTitleDecor'}>
                  <div>
                    <div className={'principiosItemTitle'}>02</div>
                    <img  alt="" className={'principiosDecor'} src={principiosDecor}/>
                  </div>
                  <div className={'principiosItemTitle principiosTitleAfter'}>EXPLORACIÓN <br/>CONSTANTE.</div>
                </div>
                <div className={'principiosDescription'}>
                  Nos encanta sorprendernos, a nosotros y a nuestros consumidores. Nos mueve la curiosidad y el deseode descubrir nuevos sabores para nuestras cervezas y brindar experiencias diferentes para compartir con amigos.
                </div>
              </div>
              <div className={'principiosItem'}>
                <img  alt="" className={'principiosItemImage'} src={principiosIlus3}/>
                <div className={'principiosItemTitleDecor'}>
                  <div>
                    <div className={'principiosItemTitle'}>03</div>
                    <img  alt="" className={'principiosDecor'} src={principiosDecor}/>
                  </div>
                  <div className={'principiosItemTitle principiosTitleAfter'}>LA EXPERIENCIA <br/>ES LA CLAVE.</div>
                </div>
                <div className={'principiosDescription'}>
                  Siempre estamos en la búsqueda de nuevas actividades y propuestas para que nuestros consumidores puedan compartir con amigos sacando lo máximo de cada momento.                </div>
              </div>
              <div className={'principiosItem'}>
                <img  alt="" className={'principiosItemImage'} src={principiosIlus4}/>
                <div className={'principiosItemTitleDecor'}>
                  <div>
                    <div className={'principiosItemTitle'}>04</div>
                    <img  alt="" className={'principiosDecor'} src={principiosDecor}/>
                  </div>
                  <div className={'principiosItemTitle principiosTitleAfter'}>EL CRAFT <br/>DEMUESTRA CUIDADO.</div>
                </div>
                <div className={'principiosDescription'}>
                  El universo de las specialty beers está habitado por la creatividad y el cuidado en la elaboración. Pero este cuidado no se basa en la perfección sino en el compromiso y la pasión por seguir aprendiendo y mejorando con cada oportunidad que se nos presente.
                </div>
              </div>
              <div className={'principiosItem'}>
                <img  alt="" className={'principiosItemImage'} src={principiosIlus5}/>
                <div className={'principiosItemTitleDecor'}>
                  <div>
                    <div className={'principiosItemTitle'}>05</div>
                    <img  alt="" className={'principiosDecor'} src={principiosDecor}/>
                  </div>
                  <div className={'principiosItemTitle principiosTitleAfter'}>RESPETO POR <br/>NUESTRA TIERRA.</div>
                </div>
                <div className={'principiosDescription'}>
                  Entendemos lo que signiﬁca el respeto por la naturaleza y por el otro. Es por eso que cada acción de nuestro equipo se funda en el cuidado de la naturaleza y del entorno en el que vivimos.                </div>
              </div>
            </div>
          </section>
          {
            this.state.width > 767 ?
              <Menu menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
              :
              <MenuMobile onMenuToggle={this.onMenuToggle} menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
          }
          <Footer/>
        </div>
        }
        {!this.state.birthConsent &&
        <BirthConsent handleConsent={this.handleConsent}/>
        }
      </div>
    )
  }
}